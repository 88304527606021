@import url("https://fonts.googleapis.com/css2?family=M+PLUS+1:wght@200;300;400;500;600;700&display=swap");
:root {
  --gray: rgba(24, 129, 225, 0.4);
}

*, *::before, *::after {
  box-sizing: border-box;
}

.l {
  box-shadow: inset 0 0 0 1px red;
}

body {
  font-family: "M PLUS 1", sans-serif;
  background: #FAFAFA !important;
}

/* Firefox */
* {
  scrollbar-width: auto;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #fff;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(219, 213, 213, 0.7882352941);
  border-radius: 10px;
  border: 2px solid #FFFFFF;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #767FFE;
}

.icon {
  stroke: unset;
  stroke-width: 0px;
  fill: currentColor;
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
}

.icon-1p25 {
  transform: scale(1.25);
}

.icon-1p30 {
  transform: scale(1.3);
}

.icon-1p35 {
  transform: scale(1.35);
}

.icon-1p50 {
  transform: scale(1.5);
}

.icon-2x {
  transform: scale(2);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0 !important;
  margin: 0 !important;
}

.l {
  box-shadow: inset 0 0 0 1px red;
}

.text-gray {
  color: #A3A3A3;
}

.text-green {
  color: #72EE38;
}

.text-blue {
  color: #183972;
}

.text-orange {
  color: #FFB84E;
}

.text-lightblue {
  color: #407BFF;
}

.text-pink {
  color: #F86D6D;
}

.text-cyan {
  color: #39C9C1;
}

.text-purple {
  color: #B910BC;
}

.text-yellow {
  color: #E89416;
}

.bg-gray {
  background: #F9F9F9 !important;
}

.bg-green {
  background: #72EE38;
}

.bg-lightgreen {
  background: #E6FFDA;
}

.bg-pink {
  background: #F86D6D;
}

.bg-lightpink {
  background: #FFF8F8;
}

.bg-blue {
  background: #102ABC;
}

.bg-lightblue {
  background: #EAEEFF;
}

.bg-cyan {
  background: #39C9C1;
}

.bg-lightcyan {
  background: #ECFFFE;
}

.bg-purple {
  background: #B910BC;
}

.bg-lightpurple {
  background: #FEDDFF;
}

.bg-yellow {
  background: #E89416;
}

.bg-lightyellow {
  background: #FFF0D9;
}

.fs-9 {
  font-size: 9px;
}

.fs-14 {
  font-size: 14px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-26 {
  font-size: 26px;
}

.fs-28 {
  font-size: 28px;
}

.fs-30 {
  font-size: 30px;
}

.fs-35 {
  font-size: 35px;
}

.fs-40 {
  font-size: 40px;
}

.fs-50 {
  font-size: 50px;
}

.fw-400 {
  font-weight: 400;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.mxw-250 {
  max-width: 250px;
}

.w30px {
  width: 30px;
}

.h30px {
  height: 30px;
}

.w10 {
  width: 10%;
}

.w90 {
  width: 90%;
}

.w-200px {
  width: 200px;
}

.wh-20px {
  width: 20px !important;
  height: 20px !important;
}

.wh-40px {
  width: 40px !important;
  height: 40px !important;
}

.navbar {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  position: fixed !important;
  top: 0;
  right: 0;
  background: #EBEEFF;
  z-index: 20;
}
.navbar .userdrop {
  border: none;
  background: none;
}
.navbar .userdrop img {
  border-radius: 50%;
  margin-right: 5px;
}
.navbar .btn-group .dropdown-menu {
  border: none;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1490196078);
}
.navbar .btn-group .dropdown-menu li {
  padding: 5px 0;
}
.navbar .btn-group .dropdown-menu .img-box {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}
.navbar .btn-group .dropdown-menu .img-box img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.navbar .btn-group .dropdown-menu .text-box {
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
}
.navbar .btn-group .dropdown-menu .drop-btn {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: start;
  display: flex;
  align-items: center;
}
.navbar .btn-group .dropdown-menu .drop-btn:hover {
  color: #16057E;
}
.navbar .btn-group .dropdown-menu .drop-btn .arrow {
  right: 10px;
}

.nav-btn {
  left: 0;
  top: 60px;
  position: sticky;
  margin-top: -40px;
}

.logout-btn-line {
  background: transparent;
  border: 1px solid #FFFFFF;
  border-radius: 6px;
  padding: 4px 12px;
  color: #FFFFFF;
  transition: 200ms;
}
.logout-btn-line:hover {
  background: #0E2074;
  border: 1px solid #0E2074;
  color: #FFFFFF;
}

.wrapper-box {
  padding-top: 72px;
}

aside {
  width: 100px;
  height: calc(100vh - 62px);
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 21;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow-y: auto;
  padding: 15px;
}
aside div {
  border-radius: 15px 0 0 15px;
  padding: 10px 0 0 10px;
  text-align: center;
  height: 100%;
  background: rgb(25, 53, 183);
  background: linear-gradient(354deg, rgb(25, 53, 183) 6%, rgb(12, 21, 74) 96%);
}

.wrapper {
  transition: 500ms;
  min-height: 100vh;
  padding-left: 295px;
  padding-top: 66px;
  padding-right: 15px;
  position: relative;
  z-index: 18;
  background: #FFFFFF;
}

.bg-gray {
  background: #e9e5e6;
}

.grd-btn {
  flex: 1 1 auto;
  padding: 30px;
  display: flex;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
}

.grd-btn:hover {
  background-position: right center !important;
}

.grd-btn {
  background-image: linear-gradient(90deg, #0E1F6E 0%, #112EDB 100%);
  padding: 10px 20px;
  border: none;
}

::-moz-placeholder {
  color: #E0E0E0 !important;
  /* Firefox */
}

::placeholder {
  color: #E0E0E0 !important;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #E0E0E0;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #E0E0E0;
}

hr {
  margin: 1rem 0;
  color: #d8d7d7 !important;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

.signin-box {
  width: 100%;
  height: 100vh;
}
.signin-box .loginfram {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -10;
}

.signin-left .group {
  width: 100%;
  max-width: 480px;
}

.aside-form ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.aside-form ul li {
  color: #FAFAFA !important;
  padding-bottom: 20px;
}
.aside-form ul li button {
  border: none;
  padding: 5px;
  border-radius: 12px 0 0 12px;
  background: transparent;
  color: #FAFAFA !important;
  font-weight: 500;
  font-size: 10px;
  width: 100%;
}
.aside-form ul li:hover,
.aside-form ul li :active {
  transition: 5000ms;
}
.aside-form ul li:hover button,
.aside-form ul li :active button {
  background: #FAFAFA;
  color: #1934B5 !important;
}
.aside-form ul li:hover span,
.aside-form ul li :active span {
  color: FAFAFA;
}

.login-input {
  width: 100%;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2509803922);
  border: none;
  border-radius: 8px;
  margin: 8px 0;
  outline: none;
  padding: 16px;
  box-sizing: border-box;
  transition: 0.3s;
  border-left: 10px solid #454E8F;
}

.login-input:focus {
  border-color: dodgerBlue;
  box-shadow: 0 0 8px 0 dodgerBlue;
}

.inputWithIcon .login-input {
  padding-left: 60px;
}

.inputWithIcon {
  position: relative;
}

.inputWithIcon i {
  position: absolute;
  left: 0;
  top: 24px;
  padding-left: 20px;
  padding-right: 10px;
  color: #aaa;
  transition: 0.3s;
  border-right: 1px solid #2D6192;
}

.w-auto {
  width: auto !important;
}

.inputWithIcon .login-input:focus + i {
  color: dodgerBlue;
  border-color: dodgerBlue;
}

.inputWithIcon.inputIconBg .login-input:focus + i {
  color: #fff;
  background-color: dodgerBlue;
}

.tabs-row .nav-pills {
  display: flex;
  justify-content: space-between;
}
.tabs-row .nav-pills .nav-item {
  background: #FFFFFF;
  width: 48%;
}
.tabs-row .nav-pills .nav-item .nav-link {
  font-weight: 700;
  font-size: 20px;
}

.nav-item .nav-link {
  background: none;
  border: 1px solid #000000 !important;
  border-radius: 10px !important;
  color: #2A2A2A !important;
  padding: 8px 20px !important;
  width: 100%;
}
.nav-item .nav-link:hover {
  background: linear-gradient(90deg, #0E2078 -0.3%, #112ED8 130.42%) !important;
  border: 1px solid #112ED8 !important;
  color: #FFFFFF !important;
  transition: 400ms;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #FFFFFF !important;
  background: linear-gradient(90deg, #0E2078 -0.3%, #112ED8 130.42%);
  border: 1px solid #112ED8 !important;
  border-radius: 29px;
}

.card {
  border-radius: 25px !important;
}
.card .card-box {
  border: none !important;
}
.card .card-box ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.card .card-box ul li {
  font-weight: 400;
  font-size: 16px;
  padding: 8px 20px;
  border-top: 1px solid #E0E0E0;
}
.card .card-box .icon-box {
  width: -moz-min-content;
  width: min-content;
  margin: 15px 0 15px 20px;
}
.card .card-box .btn-box {
  margin: 20px;
}

.tracking-btn {
  font-weight: 600;
  border: 1px solid #0E2181;
  color: #0E2181;
  background: none;
  border-radius: 30px;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  width: 120px;
}
.tracking-btn .icon {
  font-size: 28px !important;
  position: absolute;
  right: 40px;
}
.tracking-btn:hover {
  background: linear-gradient(90deg, #0E2078 -0.3%, #112ED8 130.42%) !important;
  border: 1px solid #112ED8 !important;
  color: #FFFFFF !important;
  transition: 200ms;
}

.dash-card {
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1019607843);
  background: #FFFFFF;
  border-radius: 20px;
  padding: 15px;
  height: 100%;
}

.line {
  border-left: 1px solid #1934B6;
  padding-left: 10px;
}
.line .icon-box {
  padding: 5px;
  background: #102ABC;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pie-chart .apexcharts-legend-series {
  display: flex;
  align-items: center;
  padding: 4px 0;
}
.pie-chart .apexcharts-legend-series span {
  font-weight: 500 !important;
  font-size: 14px !important;
}
.pie-chart .apexcharts-legend-series .apexcharts-legend-marker {
  border-radius: 0px !important;
  width: 20px !important;
  height: 20px !important;
}

.apexcharts-toolbar {
  display: none !important;
}

.normal-tbl table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
.normal-tbl table td,
.normal-tbl table th {
  text-align: center;
  padding: 10px 8px;
}
.normal-tbl table .tr-bg {
  background: #F6F6F6 !important;
}
.normal-tbl tr:nth-child(odd) {
  background: #FBFBFB;
  border: 1px solid #f3f3f3;
}
.normal-tbl button {
  padding: 0;
  margin: 0;
  background: none;
  border: none;
}
.normal-tbl .arrow-btn:hover {
  color: #097BA9;
}

.aside-new {
  transition: 500ms;
  width: 280px;
  height: calc(100vh - 66px);
  background: #FAFBFC;
  border-right: 1px solid #E9EBF0;
  position: fixed;
  top: 66px;
  left: 0;
  z-index: 21;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow-y: auto;
}
.aside-new.scroll-disabled {
  pointer-events: none;
}
.aside-new .dashboard-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 37px 40px 37px;
}
.aside-new .dashboard-logo a {
  text-decoration: none;
}
.aside-new .dashboard-logo a img {
  min-width: 80px;
}
.aside-new .dashboard-logo button {
  background: transparent;
  cursor: pointer;
  color: #8B8B8B;
  padding: 0;
  border: none;
  outline: none;
  line-height: 1;
  font-size: 30px;
}
.aside-new .dashboard-logo button:hover {
  color: #1A71A7;
}
.aside-new strong {
  padding-left: 20px;
  font-weight: 600;
  font-size: 18px;
}
.aside-new ul {
  list-style-type: none;
  padding: 0 20px;
  margin: 0;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
.aside-new ul li {
  padding-bottom: 8px;
}
.aside-new ul li:not(:last-child) {
  border-bottom: 1px solid #F1F1F1;
}
.aside-new ul .nav-link {
  text-decoration: none;
  height: 44px;
  padding-left: 15px;
  padding-right: 10px;
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  transition: 300ms;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}
.aside-new ul .nav-link.active, .aside-new ul .nav-link:hover {
  background: #E9F2FF;
  color: #097BA9;
}
.aside-new ul .nav-link.nav-toggle::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  right: 15px;
  top: calc(50% - 6px);
  transform: rotate(-135deg);
  transition: 300ms;
}
.aside-new ul .nav-link.nav-toggle:hover::before {
  border-left-color: #097BA9;
  border-bottom-color: #097BA9;
}
.aside-new ul .nav-link.nav-toggle.active {
  color: #097BA9;
  background-color: #E9F2FF;
}
.aside-new ul .nav-link.nav-toggle.active::before {
  top: calc(50% - 8px);
  transform: rotate(-45deg);
  border-left: 2px solid #097BA9;
  border-bottom: 2px solid #097BA9;
}
.aside-new ul .nav-link strong {
  padding-left: 12px;
  pointer-events: none;
}
.aside-new ul .navmore-link {
  position: relative;
  margin: 0 auto;
  border-radius: 5px;
  display: none;
}
.aside-new ul .navmore-link.active {
  display: block;
}
.aside-new ul .navmore-link a {
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
  padding: 10px 44px;
  font-weight: 600;
  font-size: 16px;
  color: #2A2A2A;
  border-radius: 5px;
}
.aside-new ul .navmore-link a:hover,
.aside-new ul .navmore-link a :active {
  color: #097BA9;
  background: #E9F2FF;
}

.signin-box {
  position: absolute;
  height: 100vh;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
}

.signin-left {
  background: #FFFFFF;
  height: 100%;
  min-height: 100%;
  width: 100%;
  position: relative;
}
.signin-left img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.signin-right {
  background: #F9F9F9;
  height: 100%;
  width: 100%;
  padding: 80px 80px 40px 80px !important;
}
.signin-right .btn-box {
  background: #EEEEEE;
  border-radius: 6px;
  padding: 8px;
}
.signin-right .btn-box button {
  background: transparent;
  border: none;
  padding: 8px;
  border-radius: 5px;
}
.signin-right .btn-box button:hover,
.signin-right .btn-box button :active {
  background: #FFFFFF;
}
.signin-right .anchor a {
  text-decoration: none;
  color: #055687;
}
.signin-right .anchor .line {
  text-decoration: underline;
}

.input-shadow {
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
}

::-moz-placeholder {
  color: #E0E0E0 !important;
  /* Firefox */
}

::placeholder {
  color: #E0E0E0 !important;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #E0E0E0;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #E0E0E0;
}

.menu-btn {
  background: none;
  border: none;
}
.menu-btn:hover {
  color: #097BA9;
}

.shrink-menu .aside-new {
  transition: 500ms;
  width: 120px;
}
.shrink-menu .aside-new .dashboard-logo {
  flex-direction: column;
}
.shrink-menu .aside-new .dashboard-logo button {
  margin-top: 10px;
}
.shrink-menu .aside-new .invite-box {
  display: flex;
  justify-content: center;
}
.shrink-menu .aside-new .invite-box span {
  display: none;
}
.shrink-menu .aside-new .aside-modelbtn {
  display: flex;
  justify-content: center;
}
.shrink-menu .aside-new .aside-modelbtn span,
.shrink-menu .aside-new .aside-modelbtn .icon {
  display: none;
}
.shrink-menu .aside-new ul {
  word-break: break-all;
}
.shrink-menu .aside-new ul .nav-link {
  display: flex;
  justify-content: center;
  padding-left: 10px;
}
.shrink-menu .aside-new ul .nav-link strong {
  display: none;
}
.shrink-menu .aside-new .navmore-link {
  padding: 0px;
}
.shrink-menu .aside-new .navmore-link a {
  padding: 10px 5px;
  font-size: 13px;
}
.shrink-menu .aside-new .nav-toggle::before {
  content: "";
  display: none;
}
.shrink-menu header {
  width: calc(100% - 110px);
  margin-left: 147px;
  padding-left: 37px;
}
.shrink-menu .wrapper {
  padding-left: 137px;
  transition: 500ms;
}
.shrink-menu *::-webkit-scrollbar {
  width: 10px;
}
.shrink-menu *::-webkit-scrollbar-thumb {
  border: 2px solid #ffffff;
}

.normal-btn {
  background: none;
  border: 1px solid black;
}
.normal-btn:hover {
  background: rgb(14, 31, 110);
  background: linear-gradient(90deg, rgb(14, 31, 110) 0%, rgb(17, 46, 219) 100%);
  color: #FAFAFA;
  border: 1px solid #1734b8 !important;
}

.eye-btn {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 40px;
  top: 30%;
  color: #aaaaaa;
}

.border-btn {
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  text-decoration: underline;
  font-weight: 500;
  font-size: 18px;
  color: #0D1A5A;
}
.border-btn:hover {
  color: #097BA9;
}

.vision {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
}
.vision .icon {
  color: #515456;
}

.skeleton {
  position: relative;
  overflow: hidden;
}

.skeleton::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, transparent, rgba(219, 219, 219, 0.5), transparent);
  animation: loading 1.5s infinite;
}

.skeleton > * {
  visibility: hidden;
}

@keyframes loading {
  to {
    left: 100%;
  }
}
.input-box {
  display: block;
  height: 36px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  width: 100%;
  outline: none;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  position: relative;
}
.input-box.input-file input[type=file] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: transparent;
  opacity: 0;
  z-index: 1;
}
.input-box.input-file span {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  left: 0;
  top: 0;
  padding-left: 15px;
  padding-right: 15px;
  z-index: 0;
}

.input-box-no-border input {
  border: none;
  outline: none;
  border-bottom: 0.5px solid #ccc;
  padding: 10px 0;
}
.input-box-no-border ::-moz-placeholder {
  color: #2a2a2a !important;
}
.input-box-no-border ::placeholder {
  color: #2a2a2a !important;
}

.input-date {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  position: relative;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="%231a31b2" viewBox="0 0 16 16"><path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"/> <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/> </svg>');
  background-repeat: no-repeat;
  background-size: 1.1rem 1.1rem;
  background-position: calc(100% - 10px) center;
}

.input-date::-webkit-inner-spin-button,
.input-date::-webkit-calendar-picker-indicator {
  opacity: 0;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 100%;
  position: absolute;
}

.button-default {
  border-color: #b3b3b3;
}
.button-default:hover, .button-default:focus {
  background-color: #eee;
  border-color: #999;
}
.button-default:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.15);
}

.button {
  display: inline-block;
  padding: 0.56rem 0.75rem;
  font-size: 1rem;
  line-height: 1;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border: 1px solid #212529;
  border-radius: 0.375rem;
  background-color: #ffffff;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-card textarea {
  min-height: 60px;
}

.date-picker-body {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 1000;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1490196078);
  padding: 10px;
  border-radius: 10px;
  right: 20px;
  top: 130px;
}

.text-truncate:hover {
  text-overflow: initial;
  max-width: -moz-max-content;
  max-width: max-content;
  z-index: 200;
  white-space: normal;
}

.status-card {
  background: #FFFFFF;
  border-radius: 25px;
  font-size: 12px;
  padding: 10px 0;
}
.status-card ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.status-card ul li {
  padding: 8px 10px;
}
.status-card ul li:not(:last-child) {
  border-bottom: 1px solid #F3F3F3;
}

.mapboxgl-popup-content {
  border-radius: 10px !important;
  padding: 0% !important;
}
.mapboxgl-popup-content .mapboxgl-popup-close-button {
  font-size: 20px;
}/*# sourceMappingURL=index.css.map */